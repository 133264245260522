import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { getTestCookies, getTestUrl } from "../helpers/app";
import { generateMockOfNativeSDK } from "../helpers/nativeSDK";
export var getWebViewUrl = function(url, locale, isPortal) {
    return "".concat(url).concat(isPortal ? "/portal" : "", "/").concat(locale);
};
var configureRequestHeaders = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(page) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return page.route("**/*", function() {
                        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(route) {
                            var url, isPreviewUrl, isProductionUrl, isStagingUrl, headers;
                            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                while(1)switch(_ctx.prev = _ctx.next){
                                    case 0:
                                        url = route.request().url();
                                        isPreviewUrl = url.includes("deploy-preview");
                                        isProductionUrl = url.includes("finn.com") || url.includes("finn.auto");
                                        isStagingUrl = url.includes("staging");
                                        if (!url.includes("widget.ultimate.ai")) {
                                            _ctx.next = 7;
                                            break;
                                        }
                                        route.abort();
                                        return _ctx.abrupt("return");
                                    case 7:
                                        if (!(isPreviewUrl || isProductionUrl || isStagingUrl)) {
                                            _ctx.next = 11;
                                            break;
                                        }
                                        {
                                            route.continue();
                                        }
                                        _ctx.next = 16;
                                        break;
                                    case 11:
                                        _ctx.next = 13;
                                        return route.request().allHeaders();
                                    case 13:
                                        headers = _ctx.sent;
                                        // we delete our authorization header to avoid stripe issue
                                        delete headers.authorization;
                                        route.continue({
                                            headers: headers
                                        });
                                    case 16:
                                    case "end":
                                        return _ctx.stop();
                                }
                            }, _callee);
                        }));
                        return function(route) {
                            return _ref.apply(this, arguments);
                        };
                    }());
                case 2:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function configureRequestHeaders(page) {
        return _ref.apply(this, arguments);
    };
}();
export var createNativeDriver = function(page, screenName, locale, context) {
    var messages = [];
    var onMessage = function(message) {
        var msg = JSON.parse(message);
        // we need to store all received messages to be able to check them
        if (msg.event) {
            console.log(msg.event);
        }
        messages.push(msg);
    };
    var nativeDriver = {
        setup: function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(path) {
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            page.setDefaultTimeout(15000);
                            _ctx.next = 3;
                            return context.addCookies(getTestCookies(locale));
                        case 3:
                            _ctx.next = 5;
                            return configureRequestHeaders(page);
                        case 5:
                            _ctx.next = 7;
                            return page.setViewportSize({
                                width: 375,
                                height: 812
                            });
                        case 7:
                            _ctx.prev = 7;
                            _ctx.next = 10;
                            return page.exposeFunction("__test__onMessage", onMessage);
                        case 10:
                            _ctx.next = 14;
                            break;
                        case 12:
                            _ctx.prev = 12;
                            _ctx.t0 = _ctx["catch"](7);
                        case 14:
                            _ctx.t1 = page;
                            _ctx.next = 17;
                            return generateMockOfNativeSDK(screenName);
                        case 17:
                            _ctx.t2 = _ctx.sent;
                            _ctx.t3 = {
                                content: _ctx.t2
                            };
                            _ctx.next = 21;
                            return _ctx.t1.addInitScript.call(_ctx.t1, _ctx.t3);
                        case 21:
                            if (!path) {
                                _ctx.next = 24;
                                break;
                            }
                            _ctx.next = 24;
                            return nativeDriver.navigate(path);
                        case 24:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee, null, [
                    [
                        7,
                        12
                    ]
                ]);
            }));
            return function(path) {
                return _ref.apply(this, arguments);
            };
        }(),
        cleanup: function() {
            messages = [];
        },
        navigate: function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(path) {
                var isPortal, url;
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            isPortal = path.includes("mycars") || path.includes("myaccount");
                            url = "".concat(getWebViewUrl(getTestUrl(), locale, isPortal)).concat(path.includes("/auth") ? "/mobile".concat(path) : path);
                            _ctx.next = 4;
                            return page.goto(url, {
                                timeout: 60000
                            });
                        case 4:
                            _ctx.next = 6;
                            return page.waitForLoadState();
                        case 6:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function(path) {
                return _ref.apply(this, arguments);
            };
        }(),
        get: {
            currentUrl: function() {
                return page.url();
            },
            currentUrlPath: function() {
                return page.url().split(locale)[1];
            },
            header: function() {
                return page.getByTestId("header");
            },
            footer: function() {
                return page.locator('[data-nativeappid="footer"]');
            },
            messages: function(type) {
                if (!type) {
                    return messages;
                }
                return messages.filter(function(m) {
                    return m.type === type;
                });
            },
            navigationMessages: function(url) {
                return nativeDriver.get.messages("navigation").filter(function(m) {
                    if (!m.url) {
                        return false;
                    }
                    if (typeof url === "string") {
                        var ref;
                        return (ref = m.url) === null || ref === void 0 ? void 0 : ref.includes(url);
                    }
                    return url.test(m.url);
                });
            },
            openDialogMessage: function(modalType) {
                return messages.find(function(m) {
                    var ref;
                    return m.type === "event:open_modal" && ((ref = m.value) === null || ref === void 0 ? void 0 : ref.includes(modalType));
                });
            }
        },
        is: {
            ok: function(name, pageSpecificMessages) {
                try {
                    if (messages.length === 0) {
                        throw new Error("No messages received");
                    }
                    var messagesToSee = [
                        "ready",
                        "event:storage_set_item",
                        "event:sdk_initialized", 
                    ].concat(pageSpecificMessages || []);
                    messagesToSee.forEach(function(type) {
                        if (!messages.some(function(message) {
                            return message.type === type;
                        })) {
                            throw new Error("Message ".concat(type, " not received"));
                        }
                    });
                } catch (e) {
                    var error = e;
                    console.log("Messages received:", messages);
                    error.message = "".concat(name, ": ").concat(error.message);
                    throw error;
                }
                return true;
            },
            messagesReceivedInOrder: function(orderedMessages) {
                var checkEqual = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : function(msg, t) {
                    return msg.type === t;
                };
                var messageIndexes = new Set();
                var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                try {
                    var _loop = function(_iterator, _step) {
                        var orderedMessage = _step.value;
                        var messageIndex = messages.findIndex(function(recentMessage, index) {
                            return checkEqual(recentMessage, orderedMessage) && !messageIndexes.has(index);
                        });
                        if (messageIndex === -1) {
                            console.log("Messages received:", messages);
                            throw new Error("Unable to find the ordered message: ".concat(orderedMessage));
                        }
                        messageIndexes.add(messageIndex);
                    };
                    for(var _iterator = orderedMessages[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true)_loop(_iterator, _step);
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally{
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return != null) {
                            _iterator.return();
                        }
                    } finally{
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }
                var sortedMessageIndexes = Array.from(messageIndexes).sort(function(a, b) {
                    return b - a;
                });
                for(var idx = 0; idx < sortedMessageIndexes.length - 1; idx++){
                    if (sortedMessageIndexes[idx] < sortedMessageIndexes[idx + 1]) {
                        throw new Error("Messages received out of order. Expected ".concat(orderedMessages[idx], " before ").concat(orderedMessages[idx + 1], "."));
                    }
                }
                return true;
            },
            eventsReceivedInOrder: function(orderedEvents) {
                return nativeDriver.is.messagesReceivedInOrder(orderedEvents, function(message, event) {
                    return message.event === event;
                });
            }
        }
    };
    return nativeDriver;
};
